#exp-block {
  .carousel.slide {
    position: static;
    @media (min-width: 992px) {
      position: relative;
    }
  }
  
  .carousel-indicators {
    li {
      height: 8px !important;
      width: 8px !important;
      background: #0a1f3a !important;
      border-radius: 50%;
      display: block;
      border: none !important;
    }
    margin-bottom: 0 !important;
    @media (min-width: 992px) {
      display: none !important;
    }
  }
}
