.candidate-card-wrap {
  border: 1px solid gainsboro;
  padding: 10px 16px 12px 16px;
  width: 100%;
  margin-bottom: 20px;
  min-height: 255px;
  .candidate-card-flex {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    .candidate-card-flex-position {
      flex: 1 1 auto;
    }
  }
}
