/* Custom select(styles for react-dropdown-select npm package) */

.react-dropdown-select-item:hover,
.react-dropdown-select-item-selected {
  background: #f3f3f3 !important;
  color: #0c74ef !important;
  border-bottom: 1px solid #fff;
}

.react-dropdown-select-dropdown {
  z-index: 101 !important;
  max-height: unset !important;
}

.react-dropdown-select-dropdown-handle {
  padding-bottom: 4px;
}

.react-dropdown-select-content {
  padding: 5px 7px;
  color: #495057;
  opacity: 0.9;
}

.react-dropdown-select {
  border-radius: 0.25rem;
}

.custom-btn:disabled,
.custom-btn:disabled:hover {
  background-color: #f0f0f0 !important;
  border-color: #f0f0f0 !important;
}
