.files-diff {
  pre,
  tr,
  td,
  span {
    font-family: 'Roboto Mono', monospace;
  }
  .hidden {
    background: rgba(255, 255, 255, 0.22);
    backdrop-filter: blur(12px);
  }
}
