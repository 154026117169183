/* 
CUSTOM CSS UTILITIES
similar as tailwindcss framework
https://tailwindcss.com/
*/

.opacity-0 {
  opacity: 0;
}

.opacity-50 {
  opacity: 0.5;
}
.bg-no-repeat {
  background-repeat: no-repeat;
}

.bg-orange-400 {
  background-color: #ff8a00;
}

.bg-orange-600 {
  background-color: #dd6b20;
}
.bg-orange-300 {
  background-color: #ff820f;
}
.bg-yellow-700 {
  background-color: #ffb800;
}
.bg-yellow-600 {
  background-color: #ffca00;
}

.bg-yellow-700 {
  background-color: darken(#ffca00, 5%);
}

.bg-blue-900 {
  background-color: #0a1f3a;
}
.bg-blue-800 {
  background-color: #3d5470;
}
.bg-blue-700 {
  background-color: #0154c3;
}
.bg-blue-500 {
  background-color: #0094ff;
}
.bg-blue-300 {
  background-color: #67b6ff;
}
.bg-blue-100 {
  background-color: rgba(12, 116, 239, 0.05);
}
.bg-blue-200 {
  background-color: #dbeafd;
}
.bg-gray-100 {
  background-color: #f0f0f0;
}
.bg-gray-200 {
  background-color: #f3f5f8;
}
.bg-gray-300 {
  background-color: #f3f3f3;
}
.bg-gray-400 {
  background-color: #f2f4f8;
}
.bg-gray-500 {
  background-color: #fcfcfc;
}
.bg-gray-600 {
  background-color: #e1e1e1;
}
.bg-gray-700 {
  background-color: #f4f4f4;
}
.bg-gray-800 {
  background-color: #fcfcfc;
}
.bg-gray-900 {
  background-color: #a4a4a4;
}

.bg-red-400 {
  background-color: #eb6d6d !important;
}
.bg-red-500 {
  background-color: #eb5757 !important;
}
.bg-green-500 {
  background-color: #27ae60 !important;
}

.bg-black {
  background-color: #0a1f3a !important;
}

.bg-gradient {
  background: linear-gradient(
    164.51deg,
    #eff7ff 23.91%,
    rgba(248, 234, 226, 0.28) 77.55%
  );
}

.hover\:opacity-50:hover {
  opacity: 0.5 !important;
}
.hover\:bg-orange-500:hover {
  background-color: #ff6b00 !important;
}

.hover\:bg-gray-400:hover {
  background-color: #f2f4f8 !important;
}

.hover\:bg-blue-600:hover {
  background-color: #2087ff !important;
}
.hover\:bg-blue-100:hover {
  background-color: rgba(219, 234, 253, 0.33) !important;
}
.hover\:bg-yellow-700:hover {
  background-color: lighten(#ffca00, 10%) !important;
}

.hover\:text-primary:hover {
  color: #0c74ef !important;
}
.hover\:text-purple:hover {
  color: #7700c0 !important;
}

.focus\:bg-orange-500:focus {
  background-color: #ff6b00 !important;
}

.focus\:border-gray-400:focus {
  border-color: #b8bbd8 !important;
}

.focus\:border-orange-400:focus {
  border-color: #ff8a00 !important;
}

.focus\:border-blue-300:focus {
  border-color: #66adff !important;
}

.focus\:outline-none:focus {
  outline: 0 !important;
}

.focus\:box-shadow-none:focus {
  box-shadow: none !important;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.border-white {
  border-color: #fff;
}

.border-gray-opacity-200 {
  border-color: rgba(248, 251, 255, 0.5) !important;
}
.border-gray-300 {
  border-color: #e4e4e4 !important;
}

.border-gray-400 {
  border-color: #b8bbd8 !important;
}

.border-gray-500 {
  border-color: #a0aec0 !important;
}
.border-gray-600 {
  border-color: #bac8d9 !important;
}
.border-gray-700 {
  border-color: #656565 !important;
}

.border-red-400 {
  border-color: #fc8181 !important;
}

.border-red-500 {
  border-color: #eb5757 !important;
}

.border-orange-400 {
  border-color: #ff8a00 !important;
}
.border-orange-300 {
  border-color: #ff820f !important;
}

.border-yellow-600 {
  border-color: #ffca00 !important;
}

.border-yellow-700 {
  border-color: #ffca64 !important;
}

.border-blue-400 {
  border-color: #0094ff !important;
}
.border-blue-300 {
  border-color: #67b6ff !important;
}
.border-blue-600 {
  border-color: #0c74ef !important;
}
.border-blue-800 {
  border-color: #0154c3 !important;
}
.border-blue-700 {
  border-color: #0c74ef !important;
}
.border-green-600 {
  border-color: #12dea1 !important;
}
.border-purple-800 {
  border-color: #3d5470 !important;
}
.border-purple-200 {
  border-color: #dadeff !important;
}

.hover\:border-gray-400:hover {
  border-color: #b8bbd8 !important;
}

.hover\:border-orange-500:hover {
  border-color: #ff6b00 !important;
}

.hover\:border-blue-500:hover {
  border-color: #0094ff !important;
}

.border-transparent {
  border-color: transparent !important;
}

.focus\:border-gray-400:focus {
  border-color: #b8bbd8 !important;
}

.focus\:border-orange-400:focus {
  border-color: #ff8a00 !important;
}

.focus\:border-blue-300:focus {
  border-color: #66adff !important;
}

.focus\:border-blue-600:focus {
  border-color: #0c74ef !important;
}

.rounded-lg {
  border-radius: 0.5rem !important;
}

.text-2-5xl {
  font-size: 1.75rem;
}

.text-2-6xl {
  font-size: 1.875rem;
}

.text-2xl {
  font-size: 1.5rem;
}

.text-22 {
  font-size: 1.375rem;
}
.text-25 {
  font-size: 1.5625rem;
}

.text-27 {
  font-size: 1.6875rem;
}

.text-3xl {
  font-size: 2rem;
}
.text-4xl {
  font-size: 2.25rem;
}

.text-4-5xl {
  font-size: 2.5rem;
}
.text-4-6xl {
  font-size: 2.75rem;
}
.text-4-7xl {
  font-size: 3rem;
}

.text-4-9xl {
  font-size: 3.375rem;
}

.text-5xl {
  font-size: 3.5rem;
}

.text-6xl {
  font-size: 4rem;
}

.text-base {
  font-size: 1rem;
}

.text-lg {
  font-size: 1.125rem;
}

.text-xl {
  font-size: 1.25rem;
}

.text-sm {
  font-size: 0.875rem;
}

.text-yellow-400 {
  color: #ffeca3;
}
.text-gray-100 {
  color: #f3f9ff;
}
.text-gray-200 {
  color: #e2e8f0;
}
.text-gray-300 {
  color: #c5cbd4;
}
.text-gray-400 {
  color: #898686;
}
.text-gray-500 {
  color: #bac8d9;
}
.text-gray-600 {
  color: #656565;
}
.text-gray-700 {
  color: #8a8a8a;
}
.text-gray-800 {
  color: #55657a;
}
.text-gray-850 {
  color: #3d5470;
}

.text-gray-800 {
  color: #55657a;
}

.text-gray-900 {
  color: #212529;
}

.text-red-400 {
  color: #fc8181;
}

.text-orange-300 {
  color: #ff820f;
}
.text-orange-500 {
  color: #ff6b00;
}
.text-black {
  color: #0a1f3a;
}

.text-blue-500 {
  color: #0094ff;
}
.text-blue-300 {
  color: #8eb3e3;
}
.text-blue-100 {
  color: #f3f9ff;
}

.text-red-500 {
  color: #eb5757;
}
.text-red-800 {
  color: #d20b0b;
}

.text-primary {
  color: #0c74ef;
}

.text-purple {
  color: #7700c0 !important;
}

.text-blue-700 {
  color: #0154c3;
}

.text-xs {
  font-size: 0.75rem;
}

.text-xxs {
  font-size: 0.625rem;
}

.text-23 {
  font-size: 1.4375rem;
}
.text-13 {
  font-size: 0.8125rem;
}

.text-15 {
  font-size: 0.9375rem;
}

.hover\:text-white:hover {
  color: #fff;
}

.h-8 {
  height: 2rem;
}
.h-10 {
  height: 2.5rem;
}
.h-12 {
  height: 3rem;
}
.h-16 {
  height: 4rem;
}
.italic {
  font-style: italic;
}

.font-bold {
  font-weight: 700 !important;
}
.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}
.shadow-md {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.focus\:outline-none:focus {
  outline: 0 !important;
}
.focus\:border-2:focus {
  border-width: 2px !important;
}
.border-0 {
  border-width: 0 !important;
}

.border {
  border-width: 1px;
}

.border-dashed {
  border-style: dashed !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-4 {
  border-width: 4px !important;
}
.border-8 {
  border-width: 8px !important;
}

.border-t-0 {
  border-top-width: 0 !important;
}

.border-r-0 {
  border-right-width: 0 !important;
}

.border-r {
  border-right-width: 1px !important;
}

.border-b-0 {
  border-bottom-width: 0 !important;
}

.border-l-0 {
  border-left-width: 0 !important;
}

.lh-32 {
  line-height: 32px;
}
.lh-24 {
  line-height: 24px;
}

.leading-none {
  line-height: 1;
}
.leading-thin {
  line-height: 1.125;
}
.leading-tight {
  line-height: 1.25;
}
.leading-snug {
  line-height: 1.375;
}
.leading-normal {
  line-height: 1.5;
}
.leading-relaxed {
  line-height: 1.625;
}
.leading-loose {
  line-height: 2;
}

.max-w-146 {
  max-width: 146px;
}

.max-w-173 {
  max-width: 173px;
}

.w-1\/12 {
  width: 8.333333%;
}
.w-2\/12 {
  width: 16.666667%;
}
.w-2\/10 {
  width: 20%;
}
.w-3\/12 {
  width: 25%;
}
.w-4\/12 {
  width: 33.333333%;
}
.w-5\/12 {
  width: 41.666667%;
}
.w-6\/12 {
  width: 50%;
}
.w-7\/12 {
  width: 58.333333%;
}
.w-8\/12 {
  width: 66.666667%;
}
.w-9\/12 {
  width: 75%;
}
.w-10\/12 {
  width: 83.333333%;
}
.w-11\/12 {
  width: 91.666667%;
}

.grid {
  display: grid;
}

.col-span-1 {
  grid-column: span 1 / span 1;
}
.col-span-2 {
  grid-column: span 2 / span 2;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}
.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.hover\:border-0:hover {
  border-width: 0px !important;
}

.min-vh-full {
  min-height: 100vh;
}
.min-vh-50 {
  min-height: 50vh;
}

.icon--3 {
  height: 3px;
  width: 3px;
}
.icon--4 {
  height: 4px;
  width: 4px;
}

.icon--8 {
  width: 8px;
  height: 8px;
}

.icon--12 {
  width: 12px;
  height: 12px;
}

.icon--16 {
  width: 16px;
  height: 16px;
}

.icon--22 {
  height: 22px;
  width: 22px;
}
.icon--24 {
  height: 24px;
  width: 24px;
}

.icon--30 {
  height: 30px;
  width: 30px;
}

.icon--32 {
  width: 32px;
  height: 32px;
}

.icon--33 {
  width: 33px;
  height: 33px;
}

.icon--56 {
  width: 56px;
  height: 56px;
}
.icon--72 {
  width: 72px;
  height: 72px;
}

.inset-0 {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.hover\:text-gray-600 {
  color: #656565 !important;
}

.hover\:text-black:hover {
  color: #0a1f3a !important;
}

@media (min-width: 640px) {
  .sm\:mb-12 {
    margin-bottom: 3rem !important;
  }
  .sm\:w-6\/12 {
    width: 50%;
  }
  .sm\:w-9\/12 {
    width: 75%;
  }
}
@media (min-width: 768px) {
  .md\:text-sm {
    font-size: 0.875rem;
  }
  .md\:text-3xl {
    font-size: 2rem;
  }
  .md\:text-15 {
    font-size: 0.9375rem;
  }
  .md\:icon--32 {
    width: 32px;
    height: 32px;
  }
  .md\:w-2\/10 {
    width: 20%;
  }

  .md\:border-0 {
    border-width: 0 !important;
  }

  .md\:border {
    border-width: 1px !important;
  }

  .md\:border-r-0 {
    border-right-width: 0 !important;
  }

  .md\:border-t {
    border-top: 1px solid #dee2e6 !important;
  }
  .md\:border-r {
    border-right: 1px solid #dee2e6 !important;
  }

  .md\:border-l {
    border-left: 1px solid #dee2e6 !important;
  }
  .md\:border-b {
    border-bottom: 1px solid #dee2e6 !important;
  }

  .md\:border-b-0 {
    border-bottom-width: 0 !important;
  }

  .md\:border-l-0 {
    border-left-width: 0 !important;
  }

  .md\:w-1\/3 {
    width: 33.333333%;
  }
  .md\:w-9\/12 {
    width: 75%;
  }
  .md\:w-10\/12 {
    width: 83.333333%;
  }
  .md\:w-11\/12 {
    width: 91.666667%;
  }
  .md\:w-6\/12 {
    width: 50%;
  }
  .md\:text-2-5xl {
    font-size: 1.75rem;
  }

  .md\:text-2xl {
    font-size: 1.5rem;
  }
  .md\:text-4-7xl {
    font-size: 3rem;
  }
  .md\:leading-snug {
    line-height: 1.375;
  }
  .md\:w-4\/12 {
    width: 33.333333%;
  }

  .md\:w-5\/12 {
    width: 41.666667%;
  }
  .md\:w-7\/12 {
    width: 58.333333%;
  }
  .md\:w-8\/12 {
    width: 66.666667%;
  }
  .md\:w-9\/12 {
    width: 75%;
  }
  .md\:w-10\/12 {
    width: 83.333333%;
  }
  .md\:w-11\/12 {
    width: 91.666667%;
  }
  .md\:mb-12 {
    margin-bottom: 3rem !important;
  }
  .md\:leading-none {
    line-height: 1 !important;
  }
  .md\:lh-32 {
    line-height: 32px !important;
  }
  .md\:text-5xl {
    font-size: 3.5rem;
  }
  .md\:text-6xl {
    font-size: 4rem;
  }
  .md\:text-xl {
    font-size: 1.25rem;
  }
  .md\:lh-32 {
    line-height: 32px;
  }
  .md\:leading-thin {
    line-height: 1.125;
  }

  .md\:text-4-6xl {
    font-size: 2.75rem;
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:text-black {
    color: #0a1f3a !important;
  }
  .md\:grid-flow-col {
    grid-auto-flow: column;
  }
  .md\:text-4xl {
    font-size: 2.25rem !important;
  }
  .md\:min-vh-50 {
    min-height: 50vh;
  }

  .md\:text-4-9xl {
    font-size: 3.375rem !important;
  }
  .md\:text-base {
    font-size: 1rem !important;
  }
  .md\:text-4-5xl {
    font-size: 2.5rem !important;
  }
  .md\:h-16 {
    height: 4rem;
  }
  .md\:lh-48 {
    line-height: 48px;
  }
}

@media (min-width: 992px) {
  .lg\:text-3xl {
    font-size: 2rem;
  }
  .lg\:text-2-6xl {
    font-size: 1.875rem;
  }
  .lg\:text-4xl {
    font-size: 2.25rem !important;
  }
  .lg\:text-lg {
    font-size: 1.125rem !important;
  }
  .lg\:text-4-9xl {
    font-size: 3.375rem !important;
  }
  .lg\:text-base {
    font-size: 1rem !important;
  }
  .lg\:hover\:bg-yellow-700:hover {
    background-color: #ffb800 !important;
  }
  .lg\:hover\:text-black:hover {
    color: #0a1f3a !important;
  }
  .lg\:text-white {
    color: #fff;
  }

  .lg\:border-r {
    border-right-width: 1px !important;
  }

  .lg\:border-b-0 {
    border-bottom-width: 0 !important;
  }

  .lg\:border-0 {
    border-width: 0 !important;
  }

  .lg\:border {
    border-width: 1px !important;
    border: 0 solid #e2e8f0 /* 2; */;
  }

  .lg\:border-yellow-600 {
    border-color: #ffca00 !important;
  }

  .lg\:text-xl {
    font-size: 1.25rem;
  }
  .lg\:text-5xl {
    font-size: 3.5rem;
  }
  .lg\:text-6xl {
    font-size: 4rem;
  }
  .lg\:text-22 {
    font-size: 1.375rem;
  }
  .lg\:leading-snug {
    line-height: 1.375;
  }

  .lg\:text-left {
    text-align: left !important;
  }

  .lg\:flex {
    display: -webkit-box;
    display: flex;
  }

  .lg\:px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .lg\:text-7xl {
    font-size: 4.5rem !important;
  }

  .lg\:text-2-5xl {
    font-size: 1.75rem !important;
  }

  .lg\:w-6\/12 {
    width: 50%;
  }

  .lg\:ml-auto {
    margin-left: auto !important;
  }
  .lg\:mr-0 {
    margin-right: 0 !important;
  }
  .lg\:leading-tight {
    line-height: 1.25 !important;
  }
  .lg\:w-9\/12 {
    width: 75%;
  }
  .lg\:w-2\/12 {
    width: 16.666667%;
  }
  .lg\:w-3\/12 {
    width: 25%;
  }
  .lg\:w-4\/12 {
    width: 33.333333%;
  }
  .lg\:w-5\/12 {
    width: 41.666667%;
  }
  .lg\:w-7\/12 {
    width: 58.333333%;
  }
  .lg\:w-8\/12 {
    width: 66.666667%;
  }
  .lg\:py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .lg\:leading-none {
    line-height: 1;
  }
  .lg\:text-blue-700 {
    color: #0154c3;
  }
  .lg\:text-7-3xl {
    font-size: 5.5rem !important;
  }
  .lg\:text-4-5xl {
    font-size: 2.5rem !important;
  }
  .lg\:text-4-3xl {
    font-size: 2.375rem !important;
  }
  .lg\:leading-thin {
    line-height: 1.125;
  }
  .lg\:text-4-7xl {
    font-size: 3rem;
  }
  .lg\:lh-48 {
    line-height: 48px;
  }

  .lg\:text-blue-500 {
    color: #0094ff !important;
  }

  .lg\:bg-blue-100 {
    background-color: rgba(12, 116, 239, 0.05);
  }
}

@media (min-width: 1200px) {
  .xl\:w-2-5\/12 {
    width: 30%;
  }
  .xl\:w-4\/12 {
    width: 33.333333%;
  }
  .xl\:w-6\/12 {
    width: 50%;
  }
  .xl\:w-7\/12 {
    width: 58.333333%;
  }
  .xl\:w-8\/12 {
    width: 66.666667%;
  }
  .xl\:py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
  .xl\:rounded {
    border-radius: 0.25rem;
  }
  .xl\:border-b-0 {
    border-bottom-width: 0 !important;
  }
  .xl\:mw-350 {
    max-width: 350px;
  }
}

@media (min-width: 1436px) {
  .xxl\:w-7\/12 {
    width: 58.333333%;
  }

  .xxl\:w-4\/12 {
    width: 33.333333%;
  }
}
