.plans {
  .border-top {
    border-top: 1px solid rgba(243, 249, 255, 0.5) !important;
  }
  .border-bottom {
    border-bottom: 1px solid rgba(243, 249, 255, 0.5) !important;
  }
  .text-gray-plan {
    position: relative;
    color: rgba(243, 249, 255, 0.66);
  }
}
