.two-months-free-trial .hero {
  background: center/contain url('../../../assets/twoMonthsFreeTrial/CVs.png')
    no-repeat;
  min-height: 340px;
}

@media (min-width: 768px) {
  .two-months-free-trial .hero {
    min-height: unset;
    background: unset;
  }
  .two-months-free-trial .hero-text-container {
    background: right/contain url('../../../assets/twoMonthsFreeTrial/CVs.png')
      no-repeat;
    background-size: 40%;
    min-height: 601px;
  }
}
