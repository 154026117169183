.custom-dropdown-toggle {
  span,
  div {
    pointer-events: none;
  }

  &:after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: '';
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
    transition: transform 0.15s ease-out;
    transform: rotate(-90deg);
  }
  &.active:after {
    transform: rotate(0deg);
  }
}

.custom-dropdown-menu {
  z-index: 1000;
  button:last-child {
    border-bottom: none !important;
  }
  button:disabled {
    &:hover {
      color: #808080 !important;
    }
  }
  button {
    border-bottom: 1px solid #dee2e6 !important;
    &:hover {
      color: #0c74ef;
    }
  }
}
