.checks {
  .hidden {
    background: rgba(255, 255, 255, 0.22);
    backdrop-filter: blur(12px);
  }
}
.checks-impl {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
