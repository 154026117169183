.acceptance-static-title-flex {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  span {
    &:first-child {
      font-weight: bold;
    }
    &:last-child {
      font-size: 12px;
    }
  }
}

.acceptance-line {
  .acceptance-records {
    white-space: nowrap;
  }
}
