.home {
  .single-platform {
    .btn-primary {
      border-color: transparent;
      background: rgba(0, 148, 255, 0.55);
    }
    .btn-primary:hover {
      background-color: rgba(0, 148, 255, 0.35);
    }
    @media (min-width: 768px) {
      .mw-md-400 {
        max-width: 400px;
      }
    }
  }
  .border-bottom {
    border-bottom: 1px solid rgba(222, 226, 230, 0.33) !important;
  }
  .text-silver {
    color: rgba(243, 249, 255, 0.66);
  }
  /* total width */

  @media (min-width: 768px) {
    .md\:border-t {
      border-top: 1px solid rgba(222, 226, 230, 0.33) !important;
    }
    .md\:border-r {
      border-right: 1px solid rgba(222, 226, 230, 0.33) !important;
    }
  }
  @media (min-width: 992px) {
    .lg\:border-b-0 {
      border-bottom: none !important;
    }
    .lg\:text-silver {
      color: rgba(243, 249, 255, 0.66);
    }
  }
}
