.btn-back {
  color: #3d5470;
  svg {
    fill: #3d5470;
  }
  transition: all 0.3s;
}
.btn-back:hover {
  color: #0c74ef;
  svg {
    fill: #0c74ef;
  }
}
