@import url('https://fonts.googleapis.com/css?family=Bebas+Neue&display=swap');
@import url('https://fonts.googleapis.com/css?family=Work+Sans:300,400,500,600,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=IBM+Plex+Sans:300,400,500,600,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=IBM+Plex+Mono&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Serif:ital,wght@0,400;0,600;1,400;1,600&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto+Mono&display=swap');

html {
  height: 100%;
}

body {
  font-weight: 400;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-font-smoothing: subpixel-antialiased;
  line-height: 25px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  overflow-x: hidden;
  height: 100%;
  width: 100%;
}

// .layout {
$blue: #0c74ef;
$indigo: #6610f2;
$purple: #8106cc;
$pink: #e22a6f;
$red: #dc3545;
$orange: #fd7e14;
$yellow: #ffc107;
$green: #28a745;
$teal: #20c997;
$cyan: #17a2b8;
$blue_dark: #0a1f3a;
$semi-white: #f1f2f7;

$primary: $blue;
$success: #27ae60;
$warning: #ffca00;
$danger: #eb5757;

$container-max-widths: (
  sm: 540px,
  md: 732px,
  lg: 960px,
  xl: 1240px,
);

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
);

$spacer: 1rem;
$spacers: ();
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
  (
    0: 0,
    1: (
      $spacer * 0.25,
    ),
    2: (
      $spacer * 0.5,
    ),
    3: (
      $spacer * 0.75,
    ),
    4: $spacer,
    5: (
      $spacer * 1.25,
    ),
    6: (
      $spacer * 1.5,
    ),
    7: (
      $spacer * 1.75,
    ),
    8: (
      $spacer * 2,
    ),
    10: (
      $spacer * 2.5,
    ),
    11: (
      $spacer * 2.75,
    ),
    12: (
      $spacer * 3,
    ),
    13: (
      $spacer * 3.25,
    ),
    14: (
      $spacer * 3.5,
    ),
    15: (
      $spacer * 3.75,
    ),
    16: (
      $spacer * 4,
    ),
    17: (
      $spacer * 4.25,
    ),
    18: (
      $spacer * 4.5,
    ),
    19: (
      $spacer * 4.75,
    ),
    20: (
      $spacer * 5,
    ),
  ),
  $spacers
);

$font-size-base: 1rem;

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/root';
@import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/type';
@import '~bootstrap/scss/images';
@import '~bootstrap/scss/code';
@import '~bootstrap/scss/grid';
@import '~bootstrap/scss/tables';
@import '~bootstrap/scss/forms';
@import '~bootstrap/scss/buttons';
@import '~bootstrap/scss/transitions';
@import '~bootstrap/scss/dropdown';
@import '~bootstrap/scss/button-group';
@import '~bootstrap/scss/input-group';
@import '~bootstrap/scss/custom-forms';
@import '~bootstrap/scss/nav';
@import '~bootstrap/scss/navbar';
@import '~bootstrap/scss/card';
@import '~bootstrap/scss/breadcrumb';
// @import "~bootstrap/scss/pagination";
@import '~bootstrap/scss/badge';
// @import "~bootstrap/scss/jumbotron";
@import '~bootstrap/scss/alert';
@import '~bootstrap/scss/progress';
@import '~bootstrap/scss/media';
@import '~bootstrap/scss/list-group';
@import '~bootstrap/scss/close';
@import '~bootstrap/scss/toasts';
@import '~bootstrap/scss/modal';
@import '~bootstrap/scss/tooltip';
@import '~bootstrap/scss/popover';
@import '~bootstrap/scss/carousel';
// @import "~bootstrap/scss/spinners";
@import '~bootstrap/scss/utilities';
// @import "~bootstrap/scss/print";

a {
  /* color: #8a8a8a; */
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

a.active {
  color: $purple;
}

a:hover {
  text-decoration: none;
  color: $purple;
}

a {
  color: $primary;
}

a:focus {
  outline: none;
}

p {
  line-height: 1.5;
}

* {
  font-family: 'IBM Plex Sans', sans-serif !important;
}

.font-memo {
  font-family: 'IBM Plex Mono', monospace !important;
}
.font-serif {
  font-family: 'IBM Plex Serif', serif !important;
}

ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}

ul li {
  list-style: none;
}

hr {
  border-top: 1px solid #e9eaec;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

button {
  svg,
  img {
    pointer-events: none;
  }
}

button:focus {
  outline: none !important;
}

footer a {
  transition: none;
}

.font-bebas {
  font-family: 'Bebas Neue', cursive !important;
}

.bg-sidebar {
  background-color: $blue_dark !important;
}

.bg-content {
  background-color: $semi-white;
}

.cursor-pointer {
  cursor: pointer;
}

.btn.btn-warning:hover {
  background-color: #ffb800 !important;
  border-color: #ffb800 !important;
}

.custom-control-input:checked ~ .custom-control-label::before,
.custom-control-input:checked ~ .custom-control-label-error::before {
  color: #fff;
  border-color: #0094ff;
  background-color: #0094ff;
}

.custom-control-input:focus ~ .custom-control-label::before,
.custom-control-input:focus ~ .custom-control-label-error::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label-error::before,
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #80bdff;
}

.custom-control-input:not(:disabled):active
  ~ .custom-control-label-error::before,
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #b3d7ff;
  border-color: #b3d7ff;
}

.custom-control-input[disabled] ~ .custom-control-label,
.custom-control-input[disabled] ~ .custom-control-label-error,
.custom-control-input:disabled ~ .custom-control-label-error,
.custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}

.custom-control-input[disabled] ~ .custom-control-label::before,
.custom-control-input[disabled] ~ .custom-control-label-error::before,
.custom-control-input:disabled ~ .custom-control-label-error::before,
.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label,
.custom-control-label-error {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
  line-height: 1;
}

.custom-control-label::before,
.custom-control-label-error::before {
  position: absolute;
  top: 0px;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: '';
  background-color: #fff;
  border: #0094ff solid 1px;
  @media (min-width: 768px) {
    top: 1.5px;
  }
}

.custom-control-label-error::before {
  border: #cb1111 solid 1px;
}

.custom-control-label::after,
.custom-control-label-error::after {
  position: absolute;
  top: 1.5px;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: '';
  background: no-repeat 50% / 50% 50%;
}

.custom-checkbox .custom-control-label-error::before {
  border-radius: 0.25rem;
}

.custom-checkbox
  .custom-control-input:checked
  ~ .custom-control-label-error::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}

.custom-checkbox
  .custom-control-input:indeterminate
  ~ .custom-control-label-error::before {
  border-color: #0094ff;
  background-color: #0094ff;
}

.custom-checkbox
  .custom-control-input:indeterminate
  ~ .custom-control-label-error::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}

.custom-checkbox
  .custom-control-input:disabled:checked
  ~ .custom-control-label-error::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-checkbox
  .custom-control-input:disabled:indeterminate
  ~ .custom-control-label-error::before {
  background-color: rgba(0, 123, 255, 0.5);
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #a3aeca;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: #a3aeca;
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: #a3aeca;
}
:-moz-placeholder {
  /* Firefox 18- */
  color: #a3aeca;
}
.btn:disabled {
  color: darken($color: #6c757d, $amount: 10%);
  background-color: #e1e1e1;
  border: none;
}

.btn-outline-dark,
.dropdown-toggle.btn-outline-dark {
  border-color: #bac8d9 !important;
  &:focus {
    box-shadow: unset;
  }
}

.btn-outline-dark:active,
.btn-outline-dark:hover,
.btn-outline-dark:focus,
.dropdown-toggle.btn-outline-dark:active,
.dropdown-toggle.btn-outline-dark:hover,
.dropdown-toggle.btn-outline-dark:focus {
  color: #343a40;
  background-color: #f2f4f8 !important;
}

.btn-outline-dark:not(:disabled):not(.disabled):active,
.btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #343a40;
  background-color: #f2f4f8 !important;
  border-color: #343a40;
}

.navlink {
  color: #000000;
  border-bottom: 2px solid transparent;
  &:hover {
    color: $blue;
    border-bottom: 2px solid $blue;
  }
  &.active {
    color: $blue;
    border-bottom: 2px solid $blue;
  }
}

.badge-danger {
  background: #eb5757;
}

.table {
  margin-bottom: 0px;
}

.table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > thead > tr > th {
  line-height: 1.8;
  border-color: #e9eaec;
}

.table.table-dark thead th {
  color: #fff;
}

.table-sm > tbody > tr > td,
.table-sm > tbody > tr > th,
.table-sm > tfoot > tr > td,
.table-sm > tfoot > tr > th,
.table-sm > thead > tr > td.table-sm > thead > tr > th {
  padding: 7.5px;
}

.table-hover tbody tr:hover {
  background-color: #f7f7f7;
}

.table-lg > tbody > tr > td,
.table-lg > tbody > tr > th,
.table-lg > tfoot > tr > td,
.table-lg > tfoot > tr > th,
.table-lg > thead > tr > td,
.table-lg > thead > tr > th {
  padding: 15px;
}

.table-xl > tbody > tr > td,
.table-xl > tbody > tr > th,
.table-xl > tfoot > tr > td,
.table-xl > tfoot > tr > th,
.table-xl > thead > tr > td,
.table-xl > thead > tr > th {
  padding: 20px;
}

.table-striped > tbody > tr:nth-of-type(odd) {
  background-color: #fbfbfb;
}

.table-hover > tbody > tr:hover {
  background-color: #fafafa;
}

.table-bordered {
  border: 1px solid #e9eaec;
}

.table > thead > tr > th {
  color: #717171;
  border-bottom: 1px solid #e9eaec;
}

.table .thead-dark th {
  background-color: #515365;
  border-color: #515365;
}

.table .thead-light th {
  background-color: #fafafa;
  border-color: #e9eaec;
  color: #717171;
}

.table th,
.table td {
  vertical-align: middle;
}

.table tr.selected {
  background-color: #fafafa;
}

.table-overflow {
  width: 100%;
  overflow-x: auto;
}

.table-overflow .title {
  line-height: 40px;
}

.shadow {
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

svg.orange-exclamation {
  rect {
    fill: #ff820f !important;
  }
}

/* Customize website's scrollbar like Mac OS
Not supports in Firefox and IE */

/* total width */
::-webkit-scrollbar {
  background-color: #fff;
  width: 16px;
}

/* background of the scrollbar except button or resizer */
::-webkit-scrollbar-track {
  background-color: #fff;
}

/* scrollbar itself */
::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 4px solid #fff;
}

/* set button(top and bottom of the scrollbar) */
::-webkit-scrollbar-button {
  display: none;
}
