.nav-right {
  .dropdown-toggle::after {
    content: '';
    margin-left: 0;
    border: 0;
  }
  .counter {
    position: absolute;
    right: -5px;
    top: -3px;
    background-color: #6fd088;
    color: #ffffff;
    line-height: 1;
    font-size: 10px;
    padding: 4px 6px;
  }

  .dropdown-animated {
    -webkit-transform-origin: top right !important;
    -moz-transform-origin: top right !important;
    -ms-transform-origin: top right !important;
    transform-origin: top right !important;
    transform: scale(0, 0) !important;
    -webkit-transform: scale(0, 0) !important;
    -moz-transform: scale(0, 0) !important;
    -ms-transform: scale(0, 0) !important;
    transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    display: block;
    margin-right: 5px;
  }

  .dropdown-animated.show {
    transform: scale(1, 1) !important;
    -webkit-transform: scale(1, 1) !important;
    -moz-transform: scale(1, 1) !important;
    -ms-transform: scale(1, 1) !important;
  }
}
