.custom-switch-wrap {
  max-height: 1.6em;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  .el-switch {
    position: relative;
    width: 3em;
    .el-switch-style {
      height: 1.64em;
      left: 0;
      background: #c0ccda;
      -webkit-border-radius: 0.8em;
      border-radius: 0.8em;
      display: inline-block;
      position: relative;
      top: 0;
      -webkit-transition: all 0.15s ease-in-out;
      transition: all 0.15s ease-in-out;
      width: 3em;
      cursor: pointer;
      &:before {
        display: block;
        content: '';
        height: 1.4em;
        position: absolute;
        width: 1.4em;
        background-color: #fff;
        -webkit-border-radius: 50%;
        border-radius: 50%;
        left: 0.1em;
        top: 0.14em;
        -webkit-transition: all 0.15s ease-in-out;
        transition: all 0.15s ease-in-out;
      }
    }
  }
}

.el-switch > input[type='checkbox'] {
  display: none;
}
.el-switch > input[type='checkbox'][disabled] + .el-switch-style {
  cursor: not-allowed;
  background-color: #d3dce6;
}
.el-switch > input[type='checkbox']:checked + .el-switch-style {
  background-color: #0c74ef;
}
.el-switch > input[type='checkbox']:checked + .el-switch-style:before {
  left: 50%;
}
.el-switch > input[type='checkbox']:checked[disabled] + .el-switch-style {
  background-color: #b0d7f5;
}
.el-switch.el-switch-blue > input[type='checkbox']:checked + .el-switch-style {
  background-color: #0c74ef;
}
.el-switch.el-switch-blue
  > input[type='checkbox']:checked[disabled]
  + .el-switch-style {
  background-color: #b0d7f5;
}
