.step-progressbar {
  list-style: none;
  counter-reset: step;
  display: flex;
  padding: 0;
}
.step-progressbar__item {
  display: flex;
  flex-direction: column;
  flex: 1;
  /* text-align: center; */
  position: relative;
  border-width: 2px;
  border-color: #bac8d9;
}
.step-progressbar__item:before {
  width: 32px;
  height: 32px;
  content: '';
  /* align-self: center; */
  background: #fff;
  color: #fff;
  border-radius: 100%;
  line-height: 32px;
  margin: 0.5em 0;
  border: 1px solid #bac8d9;
  z-index: 2;
}

.step-progressbar__item:after {
  height: 1px;
  width: calc(100%);
  content: '';
  background: #bac8d9;
  position: absolute;
  top: 1.5em;
  /* left: calc(50% + 1em); */
}

.step-progressbar__item:last-child:after {
  content: none;
}

.step-progressbar__item--active:before {
  background: #000;
}

.step-progressbar__item--complete:before {
  text-align: center;
  content: url('../assets/checked.svg');
  background: #3d5470;
  border: 2px solid #3d5470;
}
