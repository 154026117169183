.terms {
  white-space: pre-wrap;
  font-size: 1rem;
  width: 100%;
  h2 {
    font-weight: 700;
    font-size: 1.125rem;
  }
  h1 {
    font-size: 1.5rem;
    font-weight: 700;
  }
}
