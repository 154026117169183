.slide-menu {
  color: #99abb4;
  overflow-y: auto;
  // top: 66px;
  overflow-y: auto;
  transition: transform 0.3s;
  z-index: 1001;
  max-width: 225px;
}

.slide-menu-overlay {
  background-color: rgba(0, 0, 0, 0.6);
  transition: opacity 0.3s;
}
