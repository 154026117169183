.recruiter-scrollspy {
  a {
    line-height: 16px;
    color: #656565;
  }
  a.active,
  a:hover {
    color: #fff !important;
  }
}
