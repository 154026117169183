.acceptance-line {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  min-height: 30px;
  .trace-flow {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    span {
      margin: 20px 0;
    }
    img {
      width: 100%;
      border: 40px solid gainsboro;
      border-radius: 5px;
    }
    .trace-hide-btn {
      width: 100%;
      display: block;
      text-align: center;
      color: dodgerblue;
      cursor: pointer;
      padding-bottom: 20px;
      border-bottom: 1px solid gainsboro;
    }
  }
  .trace-show-btn {
    color: dodgerblue;
    cursor: pointer;
    padding-left: 16px;
  }
}
