.like-btn {
  svg {
    circle {
      fill: #fff;
      stroke: #3d5470;
    }
    path {
      fill: #656565;
    }
  }
  &:disabled,
  &:hover,
  &.active {
    svg {
      circle {
        fill: #0c74ef;
        opacity: 1;
        stroke: none;
      }
      path {
        fill: #fff;
      }
    }
  }
}
