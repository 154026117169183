.two-months-free-trial {
  .hero-text-container {
    margin-top: -120px;
    @media (min-width: 768px) {
      margin-top: 0;
    }
  }
  .hero-text {
    @media (min-width: 768px) {
      max-width: 65%;
    }
  }
  .events {
    li {
      display: flex;
      width: 100%;
    }

    .point {
      position: relative;
      color: #ccc;
      padding: 0;
    }

    .point-content {
      position: absolute;
      z-index: 2;
      right: 0;
      top: 0;
      transform: translateX(50%);
      border-radius: 50%;
      background: #f2f2f2;
    }

    .item-container {
      padding: 0 0 0 1.7rem;
      position: relative;
      width: 100%;
    }

    li .item-container:before {
      content: '';
      position: absolute;
      z-index: 1;
      left: 0;
      height: 100%;
      border-left: 1px #ccc solid;
    }

    li:last-child .item-container:before {
      content: '';
      position: absolute;
      z-index: 1;
      left: 0;
      height: 100%;
      border-left: none;
    }

    .icon-desktop {
      margin-top: -7px;
    }
  }
}
