.signin-eye {
  path {
    fill: #a3aeca;
  }
}
.signin-hide-eye {
  path {
    fill: #a3aeca;
  }
  line {
    stroke: #a3aeca;
  }
}
