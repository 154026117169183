.bg-hero {
  min-height: 125px;
}

.hiring-team .bg-hero {
  background: bottom / contain
    url('../assets/hiring_team/hero_cover_hiring.png') no-repeat;
}

.recruiters .bg-hero {
  background: bottom / contain url('../assets/recruiters/hero.png') no-repeat;
}
@media (min-width: 540px) {
  .bg-hero {
    min-height: 200px;
  }
  .landing .bg-hero {
    background-position: bottom center;
    background-size: contain;
  }
}
@media (min-width: 768px) {
  .bg-hero {
    min-height: 400px;
  }
}
@media (min-width: 1220px) {
  .landing .bg-hero,
  .hiring-team .bg-hero,
  .recruiters .bg-hero {
    background-position: bottom center;
    background-size: 1220px auto;
  }
}
