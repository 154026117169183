@media (min-width: 992px) {
  .lg\:skew-15 {
    transform: skew(-15deg);
  }

  .lg\:skew-back-15 {
    transform: skew(15deg);
  }
}
.rotate-15 {
  transform: rotate(15deg);
}
